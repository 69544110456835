import React from "react";
import Header from "./Header.js";
import "./general.css";
import { useNavigate } from "react-router-dom";

const Homepage = () => {
  let navigate = useNavigate();

  function handleDemandButton() {
    navigate('/demand-home');
  };

  function handlePlacementButton() {
    navigate('/placement-home');
  }

  function handlePackageButton() {
    navigate('/python-package');
  }

  return (
    <div>
      <Header />
      <h1 className="big_title">Transportation Electrification Homepage</h1>
      <div className="content" id="main">
        <h2 className="subtitle">Introduction</h2>
        <p>
          Transportation electrification, which refers to replacing fossil fuel use in the transportation sector
          with electricity, has evolved into a global mission to reduce greenhouse gas (GHG) emissions
          from the transportation sector. As an action, promoting the adoption of battery electric vehicles
          (BEVs), vehicles that are powered purely by electricity and produce zero emissions when in use,
          has emerged as a promising solution to an electrified low-emission transportation system.
        </p>
        <p>
          Despite widespread enthusiasm for BEVs, many research questions need to be answered in order
          to achieve large-scale BEV adoption and emissions goals, such as how to quantify the spatio-
          temporal changes in charging demand, how to build charging infrastructure in an economically
          sustainable way, and how to reduce emissions by coordinating renewable energy and charging
          planning.
        </p>
        <p>
          Our research took a system modeling approach and proposed several mathematical models and
          optimization algorithms to help answer these questions. This website provides several main
          modules:
        </p>
        <h2 className="subtitle-link" onClick={handleDemandButton} > Charging demand module</h2>
        <p>
          This module provides an activity-based simulation model and delivers BEV charging demand
          estimation in high spatio-temporal resolution.
        </p>
        <button className="btn-primary" onClick={handleDemandButton}>Charging Demand Module</button>
        <h2 className="subtitle-link" onClick={handlePlacementButton} > Charging placement module</h2>
        <p>
          This module presents a charging station placement model based on activity-based simulation that
          finds the optimized location and capacity of multi-type charging stations.
        </p>
        <button className="btn-primary" onClick={handlePlacementButton}>Charging Placement Module</button>
        <h2 className="subtitle-link" onClick={handlePackageButton} > Python package</h2>
        <p>
          This package contains all the functions and modules used in the charging demand and charging
          placement modules. It allows users to download the models and customize their case studies on
          their local computers.
        </p>
        <button className="btn-primary" onClick={handlePackageButton}>Python Package</button>
        <h2 className="subtitle">Atlanta Metropolitan Area</h2>
        <p>
          Case studies of these models are presented using the Atlanta metropolitan area, located in
          Georgia, U.S., which is the third-largest metropolitan region in the southeastern U.S. and the
          fourth-fastest-growing metropolitan area in the U.S. The area contains 21 counties and 5922
          traffic analytic zones (TAZs). For more information about the region, please visit the <a href="https://atlantaregional.org">Atlanta
            Regional Commission (ARC)</a>.
        </p>
      </div>
    </div>
  );
}

export default Homepage;
