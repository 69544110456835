import React from "react";
import Header from "./Header.js";
import "./general.css";
import { useNavigate } from "react-router-dom";

const PlacementHome = () => {
  let navigate = useNavigate();

  function handlePythonButton() {
    navigate('/python-package');
  };

  function handleResultsButton() {
    navigate('/placement')
  }
  // Link for our research is not yet available, add when paper is published
  return (
    <div>
      <Header />
      <h1 className="big_title">Electric Vehicle Charging Station Placement Case Study</h1>
      <div className="content" id="main">
        <div className="subtitle" id="intro">Introduction</div>
        <p>
          To support the rapid expansion of the BEV market, determining how to place charging
          infrastructure in an economically sustainable way remains a challenge. This study
          proposes an agent-based charging station placement model (ACPM) that provides
          economically sustainable solutions for CSLP by optimizing the location and capacity of
          multi-type charging infrastructure to maximize public charging’s expected net present
          value. The details of the method can be found in our research.
        </p>
        <h2 className="subtitle-link" onClick={handlePythonButton} > Python package</h2>
        <p>
          We have built a Python package that allows users to download and customize functions
          used in the charging demand and placement models.
        </p>
        <button className="btn-primary" onClick={handlePythonButton}>Python Package</button>
        <h2 className="subtitle-link" onClick={handleResultsButton} >Charging placement Case Study - Atlanta Metropolitan Area Results</h2>
        <p>
          This module contains the results of our research paper.
        </p>
        <button className="btn-primary" onClick={handleResultsButton}>Case Study Results</button>
      </div>
    </div>
  );
}

export default PlacementHome;
