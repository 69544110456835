import React from "react";
import Header from "./Header.js";
import "./general.css";
import { useNavigate } from "react-router-dom";

const DemandHome = () => {
  let navigate = useNavigate();

  function handleSimulationButton() {
    navigate('/live-modelN');
  };

  function handleResultsButton() {
    navigate('/demand')
  }

  return (
    <div>
      <Header />
      <h1 className="big_title">Electric Vehicle Charging Demand</h1>
      <div className="content" id="main">
        <div className="subtitle" id="intro">Introduction</div>
        <p>
          Battery electric vehicles (BEVs), vehicles that run solely on electricity and produce no
          emissions during operation, have emerged as a potentially reliable solution for a future
          zero-emission transportation system.
        </p>
        <p>
          The expansion of the BEV market calls for a rapid increase in electricity charging
          demand. The increasing demand can bring both economic benefits and challenges in
          infrastructure planning and electricity load management. How to estimate the spatio-
          temporal distribution of BEV charging demand is crucial to achieving optimal EVSE
          planning and electricity load management.
        </p>
        <p>
          This section provides an agent-based charging demand prediction model. The details of
          the method can be found in <a href="https://www.sciencedirect.com/science/article/abs/pii/S0360544222018382?via%3Dihub">our research</a>.
        </p>
        <h2 className="subtitle-link" onClick={handleSimulationButton}> Electric Vehicle Demand Simulation </h2>
        <p>
          This module provides live demand simulation in the US.
        </p>
        <button className="btn-primary" onClick={handleSimulationButton}>Electric Vehicle Demand Simulation</button>
        <h2 className="subtitle-link" onClick={handleResultsButton}> Charging demand case study- Atlanta Metropolitan Area </h2>
        <p>
          This module contains the results of our <a href="https://www.sciencedirect.com/science/article/abs/pii/S0360544222018382?via%3Dihub">research paper</a>.
        </p>
        <button className="btn-primary" onClick={handleResultsButton}>Charging Demand Results</button>
      </div>
    </div>
  );
}

export default DemandHome;
